<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-row
      v-if="saleData"
      class="print-container"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card>
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-dark ml-0"
          >
            <b-tab active>
              <template #title>
                <span>{{ $t('Sale') }}</span>
              </template>

              <section
                id="print-invoice-view"
                class="invoice-preview-wrapper"
              >

                <b-row
                  class="invoice-preview"
                >
                  <b-col
                    cols="12"
                  >
                    <div
                      class="invoice-preview-card"
                    >
                      <div class="mt-1">
                        <div class="d-flex justify-content-center bd-highlight">
                          <div class="bd-highlight text-center">
                            <b-img
                              :src="configs.business_logo"
                              width="128"
                            />
                            <h3 class="mb-0">
                              {{ configs.business_name }}
                            </h3>
                            <p class="mb-1">
                              {{ configs.business_address }}. {{ configs.business_phone }}, {{ configs.business_email }}, {{ configs.business_website }}
                            </p>
                          </div>
                        </div>

                        <!-- Spacer -->
                        <hr class="invoice-spacing">

                        <div class="d-flex bd-highlight">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark font-weight-bold">{{ $t('Invoice') }}: </span>#{{ saleData.id }}
                          </div>
                          <div class="pb-1 bd-highlight">
                            <span class="float-right"><span class="text-dark font-weight-bold">{{ $t('Date') }}: </span>{{ saleData.sale_date }}</span>
                          </div>
                        </div>
                        <div class="d-flex bd-highlight">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark mb-2 font-weight-bold">{{ $t('Branch') }}: </span>{{ saleData.branch_name }}
                          </div>
                          <div class="pb-1 bd-highlight">
                            <span class="text-dark mb-2 font-weight-bold">{{ $t('Ref') }}: </span>{{ saleData.reference }}
                          </div>
                        </div>

                        <div class="d-flex bd-highlight border-bottom">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark font-weight-bold">{{ $t('Client') }}: </span>{{ saleData.client.name }}&nbsp;
                            [{{ saleData.client.phone }} - {{ saleData.client.address }}]
                          </div>
                          <div class="pb-1 bd-highlight">
                            <b-badge
                              pill
                              :variant="`light-${resolveStatusVariant(saleData.status)}`"
                              class="text-capitalize"
                            >
                              {{ resolveStatus(saleData.status) }}
                            </b-badge>
                          </div>
                        </div>
                        <!-- Invoice Description: Table -->
                        <b-table-lite
                          class="mt-1"
                          hover
                          bordered
                          responsive
                          :items="saleData.sale_items"
                          :fields="[{ label: '', key: 'sr'}, { label: $t('Item'), key: 'item'}, { label: $t('Price'), key: 'price', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Qty'), key: 'quantity', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Unit'), key: 'unit', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Amt'), key: 'amount', tdClass: 'text-right', thClass: 'text-right'}]"
                          :small="isSmallScreen"
                        >
                          <!-- Column: Price -->
                          <template #cell(price)="data">
                            {{ data.item.price.toLocaleString() }}
                          </template>
                          <!-- Column: Amount -->
                          <template #cell(amount)="data">
                            {{ data.item.amount.toLocaleString() }}
                          </template>

                        </b-table-lite>
                        <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                          <div class="bd-highlight w-120px text-right pr-2rem">
                            {{ saleData.total_amount.toLocaleString() }}
                          </div>
                          <div class="bd-highlight pl-1 w-180px ">
                            {{ $t('Total Amount') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                          <div class="bd-highlight w-120px text-right pr-2rem">
                            {{ saleData.discount.toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1">
                            {{ $t('Discount') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                          <div class="bd-highlight w-120px text-right pr-2rem font-weight-bold pt-0-75 border-top">
                            {{ saleData.net_amount.toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1 pt-0-75 border-top">
                            {{ $t('Net') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-1">
                          <div class="bd-highlight w-120px text-right pr-2rem">
                            {{ saleData.total_receipt.toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1">
                            {{ $t('Cash Receipt') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-1">
                          <div class="bd-highlight w-120px text-right pr-2rem font-weight-bold  pt-0-75 border-top">
                            {{ (saleData.net_amount - saleData.total_receipt).toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1  pt-0-75 border-top">
                            {{ $t('Remaining Amount') }}:
                          </div>
                        </div>

                        <b-row>
                          <b-col
                            cols="12 text-center"
                          >
                            <small>
                              {{ saleData.remark }}
                            </small>
                          </b-col>
                        </b-row>

                      </div>

                    </div>
                  </b-col>
                </b-row>

              </section>
            </b-tab>
          </b-tabs>

        </b-card>

      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        xl="3"
        md="4"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Print Slip -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="printSlip"
          >
            <feather-icon
              icon="FileTextIcon"
              class="mr-1"
            />
            {{ $t('Print Slip') }}
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-1"
            />
            {{ $t('Print Invoice') }}
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-if="$can('update', 'sale')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            :to="{ name: 'sale-edit', params: { id: saleData.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-1"
            />
            {{ $t('Edit') }}
          </b-button>

          <!-- Button: Close -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="close"
          >
            <feather-icon
              icon="PowerIcon"
              class="mr-1"
            />
            {{ $t('Close') }}
          </b-button>

        </b-card>
      </b-col>

    </b-row>

  </b-overlay>

</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BButton, BTabs, BTab, BTableLite, BImg, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router/index'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import saleStoreModule from '../saleStoreModule'
import configData from '../../../common/configModule'

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BTableLite,
    BImg,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  created() {

  },
  methods: {
    // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
    resolveStatus(status) {
      if (status === 1) return this.$t('Unpaid')
      if (status === 2) return this.$t('Partially Paid')
      return this.$t('Paid')
    },
    close() {
      this.$router.push({ name: 'sale-list' })
    },
    printSlip() {
      // for printing of slip 80mm printer
      // Get HTML to print from element
      const prtHtmlStart = `
        <div id="sub-header" style="text-align:center;width:100%;">
          <img style="margin:0;" src="${this.configs.business_logo}" width="72">  
          <h4 style="margin:0;">
            ${this.configs.business_name}
          </h4>
          <p style="font-size: 13px;">
            ${this.configs.business_address}, ${this.configs.business_phone}, ${this.configs.business_website}
          </p>
        </div>
        <hr>
        <div id="sub-header" style="font-size:12px;">
          <table style="width:100%;">
            <tr>
              <td style="text-align:left;">${this.$t('Invoice')}: ${this.saleData.id}</td>
              <td style="text-align:right;">${this.$t('Date')}: ${this.saleData.sale_date}</td>
            </tr>
            <tr>
              <td style="text-align:left;">${this.$t('Branch')}: ${this.saleData.branch_name}</td>
              <td style="text-align:right;">${this.saleData.reference} [${this.resolveStatus(this.saleData.status)}]</td>
            </tr>
            <tr>
              <td colspan=2 style="text-align:left;">${this.$t('Client')}: ${this.saleData.client.name} [${this.saleData.client.phone} - ${this.saleData.client.address}]</td>
            </tr>
          </table>
        </div>
        <hr>
        <div style="font-size:12px;">
          <table style="width:100%;">
            <tr>
              <th>#</th>
              <th style="text-align:left;">${this.$t('Item')}</th>
              <th style="text-align:right;">${this.$t('Price')}</th>
              <th colspan=2 style="text-align:center;">${this.$t('Qty')}</th>
              <th style="text-align:right;">${this.$t('Amt')}</th>
            </tr>
      `

      const prtHtmlEnd = `
          <tr>
            <td colspan=5 style="text-align:right;">${this.$t('Total Amount')}:</td>
            <td style="text-align:right;">${this.saleData.total_amount.toLocaleString()}</td>
          </tr>
          <tr style="border-bottom: 1px solid black;">
            <td colspan=5 style="text-align:right;">${this.$t('Discount')}:</td>
            <td style="text-align:right;border-bottom: 1px solid black;">${this.saleData.discount.toLocaleString()}</td>
          </tr>
          <tr>
            <td colspan=5 style="text-align:right;">${this.$t('Net')}:</td>
            <td style="text-align:right;">${this.saleData.net_amount.toLocaleString()}</td>
          </tr>
          <tr>
            <td colspan=5 style="text-align:right;">${this.$t('Cash Receipt')}:</td>
            <td style="text-align:right;border-bottom: 1px solid black;">${this.saleData.total_receipt.toLocaleString()}</td>
          </tr>
          <tr>
            <td colspan=5 style="text-align:right;">${this.$t('Remaining Amount')}:</td>
            <td style="text-align:right;">${(this.saleData.net_amount - this.saleData.total_receipt).toLocaleString()}</td>
          </tr>
        </table>        
        </div>
        <div style="text-align:center;padding-bottom:10px;padding-top:5px;font-size:10px;">
          tallyaid.com
        </div>
      `
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1">
        </head>
        <body style="padding:10px;">
          ${prtHtmlStart}
          ${this.saleData.sale_items.map(this.itemTemplate).join('')}
          ${prtHtmlEnd}
        </body>
      </html>`)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.setTimeout(() => { WinPrint.close() }, 10000)
    },
    itemTemplate(item) {
      return `
          <tr>
            <td>${item.sr}</td>
            <td>${item.item}</td>
            <td style="text-align:right;">${item.price.toLocaleString()}</td>
            <td style="text-align:right;">${item.quantity}</td>
            <td style="text-align:right;">${item.unit}</td>
            <td style="text-align:right;">${item.amount.toLocaleString()}</td>
          </tr>
      `
    },
  },
  setup() {
    const currentBreakpoint = computed(() => store.getters['app/currentBreakPoint'])
    const isSmallScreen = (currentBreakpoint.value === 'sm' || currentBreakpoint.value === 'xs')
    const STORE_MODULE_NAME = 'sale'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const saleData = ref({
      sale_date: null,
      client: [],
      client_id: null,
      total_amount: 0,
      discount: 0,
      net_amount: 0,
      total_receipt: 0,
      staff_name: '',
      reference: '',
      remark: '',
      branch_name: '',
      status: null,
      sale_region_id: null,
      sale_region_name: null,
      sale_items: [],
      sale_receipts: [],
    })

    const saleId = router.currentRoute.params.id

    store.dispatch('sale/fetchOne', { id: saleId })
      .then(response => {
        const dataItems = []
        const dataReceipts = []
        const responseItems = response.data.data.sale_items
        const responseReceipts = response.data.data.sale_receipts
        for (let i = 0; i < responseItems.length; i += 1) {
          dataItems.push({
            sr: (i + 1),
            item_id: responseItems[i].item_id,
            item: `${responseItems[i].item.sku} ${responseItems[i].item.name}`,
            price: responseItems[i].price,
            quantity: responseItems[i].quantity,
            unit: responseItems[i].item.unit,
            amount: responseItems[i].amount,
          })
        }
        for (let i = 0; i < responseReceipts.length; i += 1) {
          dataReceipts.push({
            sr: (i + 1),
            receipt_date: responseItems[i].receipt_date,
            amount: responseItems[i].amount,
            reference: responseItems[i].reference,
          })
        }

        const responseData = {
          id: response.data.data.id,
          sale_date: response.data.data.sale_date,
          client: response.data.data.client,
          client_id: response.data.data.client_id,
          total_amount: response.data.data.total_amount,
          discount: response.data.data.discount,
          net_amount: response.data.data.net_amount,
          total_receipt: response.data.data.total_receipt,
          staff_name: response.data.data.staff_name,
          reference: response.data.data.reference,
          remark: response.data.data.remark,
          branch_name: response.data.data.branch_name,
          status: response.data.data.status,
          sale_region_id: response.data.data.sale_region_id,
          sale_region_name: response.data.data.sale_region_name,
          sale_items: dataItems,
          sale_receipts: dataReceipts,
        }
        saleData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          saleData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    const resolveStatusVariant = status => {
      if (status === 1) return 'danger'
      if (status === 2) return 'warning'
      if (status === 3) return 'success'
      if (status === 4) return 'secondary'
      return 'primary'
    }
    const { configs } = configData()

    return {
      isSmallScreen,
      saleData,
      printInvoice,
      configs,
      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.text-dark {
  color: #000000;
}
.w-120px {
  width: 120px;
}
.w-180px {
  width: 180px;
}
.pr-2rem {
  padding-right: 2rem !important;
}
.tabStyle {
  color : black !important;
}
[dir] .nav-tabs .nav-link {
  padding-left: 0;
}
.mb-0-75 {
  margin-bottom: 0.75rem !important;
}
.pt-0-75 {
  padding-top: 0.75rem !important;
}
</style>

<style lang="scss">
@media print {

  // Global Styles
  body, h3 {
    background-color: transparent !important;
    font-size: 27px;
    font-family: 'Pyidaungsu' !important;
    color: #000000 !important;
  }
  p {
    line-height: 2.5rem !important;
  }
  img {
    width: 164px;
  }
  .table thead th, .table tfoot th {
    font-size: 27px;
  }
  .w-120px {
    width: 160px;
  }
  .table-bordered th, .table-bordered td {
    border: 1px solid #000 !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pr-2rem {
    padding-right: 5px !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .print-container {
    > [class*="col-"] {
      flex: 0 0 100%;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  [dir] .card {
    border: none !important;
  }
  [dir] .card-body {
    padding: 0;
  }
  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
